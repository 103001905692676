import React, {useState, useRef, useEffect} from "react"
import "../index.scss"

import Layout from "src/components/layout"
import Head from "src/components/Head"

import SportObjectsSearch from "src/components/clients/SportObjectsSearch/SportObjectsSearch"
import Start from "src/components/Start/Start"
import Top from "src/components/Top/Top"
import Top2 from "src/components/Top2/Top2"
import Description from "src/components/companies/Description/Description"
import HowItWorks from "src/components/companies/HowItWorks/HowItWorks"
import Benefits from "src/components/companies/Benefits/Benefits"
import YoutubeVideo, { YOUTUBE_VIDEO_LINKS } from "src/components/YoutubeVideo/YoutubeVideo"

import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "react-i18next"
import COUNTRY from "../../constants";
import BRANDS from "../../brands";

const ClientsMain = ({location}) => {
  const data = useStaticQuery(graphql`
    query mainPageClientsLtEn {
      site {
        siteMetadata {
          senderPath
          countries {
            lt {
              hotLine {
                tel
                title
                telephone
                telephoneTitle
              }
              statistics {
                supplier {
                  subscription_amount_array {
                    key
                    value
                  }
                }
                activity {
                  subscription_amount_array {
                    key
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  let vip_amount = 0
  data.site.siteMetadata.countries.lt.statistics.supplier.subscription_amount_array.map(({key, value})=> {
    if(key ==="vip"){
      vip_amount = value
    }
  })

  let vip_activities_amount = 0
  data.site.siteMetadata.countries.lt.statistics.activity.subscription_amount_array.map(({key, value})=>{
    if(key ==="vip"){
      vip_activities_amount = value
    }
  })

  const { t,i18n } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage(COUNTRY.lt_en)
  }, [i18n])

  const [email, setEmail] = useState("")
  const [emailDisabled, setEmailDisabled] = useState(false)
  const [form, setForm] = useState({
    company: "",
  })
  const sendForm = (form) => {
    setForm(form)
    return fetch(data.site.siteMetadata.senderPath, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    })
      .then((response) => {
        alert(t("sendFormAlert"))
      })
      .catch((e) => {
        console.error(e)
      })
  }
  const transferEmail = (email) => {
    setForm(Object.assign(form, { email }))
    setEmailDisabled(true)
  }

  const startRef = useRef()
  return (
    <div className="main-page">
      <Head
        lang="lt_en"
        title={t("head.index.title", { brand: BRANDS.SPORTBENEFIT.brand })}
        description={t("head.description", { brand: BRANDS.SPORTBENEFIT.brand })}
        slug="/"
      />
      <Layout
        hotLine={data.site.siteMetadata.countries.lt.hotLine}
        location={location}
        country="lt_en"
      >
        <Top2
          type="clients"
          startRef={startRef}
          email={email}
          setEmail={setEmail}
          transferEmail={transferEmail}
          platinum_amount={vip_amount}
          platinum_activities_amount={vip_activities_amount}
          country="lt_en"
        />
        <SportObjectsSearch country="lt"/>
        <Top
          type="companies"
          startRef={startRef}
          email={email}
          setEmail={setEmail}
          transferEmail={transferEmail}
          platinum_amount={vip_amount}
          country="lt_en"
        />
        <YoutubeVideo videoSrc={YOUTUBE_VIDEO_LINKS.lt_en} />
        <Description
          platinum_amount={vip_amount}
          platinum_activities_amount={vip_activities_amount}
        />
        <HowItWorks />
        <Benefits />
        <Start
          type="companies"
          startRef={startRef}
          email={email}
          emailDisabled={emailDisabled}
          setEmail={setEmail}
          setForm={sendForm}
        />
      </Layout>
    </div>
  )
}

export default ClientsMain
